import {NxCheckbox, NxInput, NxMultiSelect, NxNumberInput, NxPercentageInput, NxSelect} from '@nextbank/ui-components';
import clsx from 'clsx';
import {isNil} from 'lodash';
import React, {ChangeEvent, ReactElement, useContext, useMemo, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ReactComponent as NextLineIcon} from '../../../../assets/images/icon-next-line.svg';
import {CheckType, OccupationAndIncomeCheckItem, TableCheckItem} from '../../../../shared/model/check.model';
import {FieldType} from '../../../../shared/model/field.model';
import {getFieldTypeOptions, isDictionaryType} from '../../../../utils/custom-fields-utils';
import {getCheckboxValue, getInputValue} from '../../../../utils/input-utils';
import {toSelectOption} from '../../../../utils/select-options-utils';
import {TransHelper} from '../../../../utils/trans-helper';
import CustomDictionarySidePanel from '../../../shared/custom-dictionary/CustomDictionarySidePanel';
import RequiredButtonSwitch from '../../../shared/icon-button-switches/RequiredButtonSwitch';
import SelfCareButtonSwitch from '../../../shared/icon-button-switches/SelfCareButtonSwitch';
import {LoanConfigurationContext} from '../../loan-configuration/LoanConfiguration';
import {CheckProps, DataTestIds} from './check.model';
import styles from './Check.module.scss';
import {Tooltip} from '@material-ui/core';
import {ReactComponent as InfoIcon} from '../../../../assets/images/icon-info.svg';
import TableCheckSidePanel from '../../loan-configuration/steps/eligibility-criteria/side-panel/TableCheckSidePanel';

const SYSTEM_ACTION_OWNER_VALUE = 'SYSTEM_ACTION_OWNER_VALUE';
const CONFIGURATION_CHECKS = [CheckType.INTEGER.toString(),
  CheckType.BOOLEAN.toString(),
  CheckType.CASH.toString(),
  CheckType.PERCENTAGE.toString(),
  CheckType.TABLE.toString()];

export const PrefixTrans = TransHelper.getPrefixedTrans('LOAN_CONFIGURATIONS.CHECKS');

const NextLineDivider = ({tablet = false}: {tablet?: boolean}): ReactElement =>
  <div className={clsx(styles.nextLineMarker, {[styles.desktopOnly]: !tablet, [styles.tabletOnly]: tablet})}>
    <NextLineIcon />
  </div>;

const automaticCheckTypeOptions = [
  toSelectOption('ADDRESS_PROVINCE_CHECK', CheckType.PROVINCE),
  toSelectOption('AGE_CHECK', CheckType.AGE),
  toSelectOption('INCOME_CHECK', CheckType.INCOME),
  toSelectOption('RESIDENCY_CHECK', CheckType.RESIDENCY),
  toSelectOption('NATIONALITY_CHECK', CheckType.NATIONALITY),
  toSelectOption('OCCUPATION', CheckType.OCCUPATION),
  toSelectOption('OCCUPATION_AND_INCOME', CheckType.OCCUPATION_AND_INCOME),
  toSelectOption('INTEGER_CHECK', CheckType.INTEGER),
  toSelectOption('BOOLEAN_CHECK', CheckType.BOOLEAN),
  toSelectOption('CASH_CHECK', CheckType.CASH),
  toSelectOption('PERCENTAGE_CHECK', CheckType.PERCENTAGE),
  toSelectOption('TABLE_CHECK', CheckType.TABLE)
];

const automaticChecksInitValues = {
  provinceIds: undefined,
  residenceIds: undefined,
  nationalityIds: undefined,
  occupationIds: undefined,
  minAge: undefined,
  maxAge: undefined,
  minIncome: undefined,
  maxIncome: undefined
};

const Check = (
  {
    check,
    setCheck,
    endButton,
    phaseId,
    dictionaryId,
    provinceOptions,
    residenceOptions,
    ageCheckTypeOptions,
    nationalityOptions,
    occupationOptions,
    handleAutomaticCheck = false
  }: CheckProps
): ReactElement => {

  const {t} = useTranslation();
  const {roles} = useContext(LoanConfigurationContext);
  const initialOccupationIds = check.occupationAndIncomeItems?.map(item => item.occupationId) || [];
  const [occupationIds, setOccupationIds] = useState<number[]>(initialOccupationIds);
  const {automatic, proofType, required, selfCare, type} = check;

  const actionOwnerOptions = useMemo(() => ([
    ...(handleAutomaticCheck ? [toSelectOption('System', SYSTEM_ACTION_OWNER_VALUE, false)] : []),
    ...(roles ?? [])
  ]), [roles, handleAutomaticCheck]);

  const actionOwnerSelectValue = check.automatic 
  ? [SYSTEM_ACTION_OWNER_VALUE] 
  : (check.roleIds ? check.roleIds.map(String) : []);

  /**
   *  When action owner select changes it sets:
   *  - automatic (true if action owner set to System, false if not)
   *  - approvalId (emptied if action owner set to System, otherwise it's set to selected value)
   *  - type (Manual Check if action owner set to System, otherwise it's emptied)
   */
  const handleActionOwnerChange = (newActionOwnerValue: string[]): void => {
    // handles when SYSTEM role is currently selected and is switch to other roles
    if (newActionOwnerValue.length > 1 && newActionOwnerValue[0] === SYSTEM_ACTION_OWNER_VALUE) {
      newActionOwnerValue = newActionOwnerValue.slice(1);
    }

    // unselects other roles when System role is selected
    if (newActionOwnerValue.length > 1 && newActionOwnerValue.some(i => i === SYSTEM_ACTION_OWNER_VALUE)) {
      newActionOwnerValue = [SYSTEM_ACTION_OWNER_VALUE];
    }

    const automatic = handleAutomaticCheck && newActionOwnerValue[0] === SYSTEM_ACTION_OWNER_VALUE;

    setCheck({
      ...check,
      automatic,
      roleIds: !automatic || !newActionOwnerValue ? newActionOwnerValue.map(Number) : [],
      type: !automatic ? CheckType.MANUAL : '' as CheckType,
      proofType: !automatic ? check.proofType : undefined,
      ...automaticChecksInitValues
    });
  };

  const handleInputChange = (prop: 'name'): (event: ChangeEvent) =>
    void => (event: ChangeEvent): void => setCheck({...check, [prop]: getInputValue(event)});

  const handleSwitchChange = (prop: 'selfCare' | 'required'): void => setCheck({...check, [prop]: !check[prop]});

  const handleDictionaryChange = (dictionaryId: number): void => setCheck({...check, dictionaryId});

  const handleProofTypeChange = (proofType: string | null): void =>
    setCheck({...check, proofType: proofType as FieldType});

  const handleNumberInputChange = (prop: 'minAge' | 'maxAge' | 'minIncome' | 'maxIncome' | 'integerValue' | 'cashValue' | 'percentageValue'): (value: number | null) =>
    void => (value: number | null): void => setCheck({...check, [prop]: value});

  const handleAgeCheckTypeChange = (ageCheckTypeId: number | null): void => setCheck({
    ...check,
    ageCheckTypeId: ageCheckTypeId ?? undefined
  });

  const handleAddressProvinceChange = (provinceIds: number[]): void => setCheck({...check, provinceIds});
  const handleResidenceChange = (residenceIds: number[]): void => setCheck({...check, residenceIds});
  const handleNationalityChange = (nationalityIds: number[]): void => setCheck({...check, nationalityIds});
  const handleOccupationChange = (occupationIds: number[]): void => setCheck({...check, occupationIds});
  const handleBooleanChange = (value: boolean | null): void => setCheck({
    ...check, ...(value !== null
      ? {booleanValue: value}
      : undefined)
  });
  const handleTableItemsChange = (items: TableCheckItem[]): void => setCheck({...check, tableItems: items});
  const handleEnableChange = (event: ChangeEvent): void => setCheck({...check, enabled: getCheckboxValue(event)});
  const handleIncomeChange = (prop: 'minIncome' | 'maxIncome',
                              index: number): (value: number | null) => void => (value: number | null) => {
    const updatedItemsCopy = [...(check.occupationAndIncomeItems || [])];
    updatedItemsCopy[index][prop] = value || undefined;
    setCheck({...check, occupationAndIncomeItems: updatedItemsCopy});
  };
  const handleOccupationsChange = (values: number[]): void => {
    setOccupationIds(values);

    const toAdd = values.filter(id => !occupationIds.includes(id));
    const toRemove = occupationIds.filter(id => !values.includes(id));

    const newItems: OccupationAndIncomeCheckItem[] = toAdd.map(id => ({occupationId: id}));

    const updatedItemsCopy = check.occupationAndIncomeItems?.filter(item =>
      !toRemove.includes(item.occupationId ?? -1)
    ) || [];

    updatedItemsCopy.push(...newItems);

    setCheck({
      ...check,
      occupationAndIncomeItems: updatedItemsCopy
    });
  };

  const ageCheckInputs = (
    <>
      <NextLineDivider tablet />
      <NextLineDivider />
      <NxNumberInput className={styles.input}
                     data-testid={DataTestIds.AGE_INPUT}
                     label={<PrefixTrans>MIN_AGE</PrefixTrans>}
                     value={check.minAge}
                     onChange={handleNumberInputChange('minAge')}
                     min={0} />
      <NxNumberInput className={styles.input}
                     data-testid={DataTestIds.AGE_INPUT}
                     label={<PrefixTrans>MAX_AGE</PrefixTrans>}
                     onChange={handleNumberInputChange('maxAge')}
                     value={check.maxAge}
                     max={199} />
      <NextLineDivider />
      <NxSelect<number> className={styles.input}
                        data-testid={DataTestIds.AGE_INPUT}
                        label={<PrefixTrans>AGE_CHECK_TYPE</PrefixTrans>}
                        onChange={handleAgeCheckTypeChange}
                        options={ageCheckTypeOptions ?? []}
                        value={check.ageCheckTypeId} />
      {endButton}
    </>
  );

  const incomeCheckInputs = (
    <>
      <NextLineDivider tablet />
      <NextLineDivider />
      <NxNumberInput className={styles.input}
                     data-testid={DataTestIds.INCOME_INPUT}
                     label={<PrefixTrans>MIN_INCOME</PrefixTrans>}
                     value={check.minIncome}
                     onChange={handleNumberInputChange('minIncome')} />
      <NxNumberInput className={styles.input}
                     data-testid={DataTestIds.INCOME_INPUT}
                     label={<PrefixTrans>MAX_INCOME</PrefixTrans>}
                     value={check.maxIncome}
                     onChange={handleNumberInputChange('maxIncome')} />
      {endButton}
    </>
  );

  const provinceCheckInput = (
    <>
      <NextLineDivider tablet />
      <NxMultiSelect<number> className={styles.input}
                             data-testid={DataTestIds.PROVINCE_SELECT}
                             label={<PrefixTrans>ADDRESS_PROVINCE</PrefixTrans>}
                             onChange={handleAddressProvinceChange}
                             options={provinceOptions ?? []}
                             value={!isNil(check.provinceIds) ? check.provinceIds : []} />
      {endButton}
    </>
  );

  const residencyCheckInput = (
    <>
      <NextLineDivider tablet />
      <NextLineDivider />
      <NxMultiSelect<number> className={styles.input}
                             data-testid={DataTestIds.RESIDENCE_SELECT}
                             label={<PrefixTrans>RESIDENCE</PrefixTrans>}
                             onChange={handleResidenceChange}
                             options={residenceOptions ?? []}
                             value={!isNil(check.residenceIds) ? check.residenceIds : []} />
      {endButton}
    </>
  );

  const nationalityCheckInput = (
    <>
      <NextLineDivider tablet />
      <NextLineDivider />
      <NxMultiSelect<number> className={styles.input}
                             data-testid={DataTestIds.NATIONALITY_SELECT}
                             label={<PrefixTrans>NATIONALITY</PrefixTrans>}
                             onChange={handleNationalityChange}
                             options={nationalityOptions ?? []}
                             value={!isNil(check.nationalityIds) ? check.nationalityIds : []} />
      {endButton}
    </>
  );

  const occupationCheckInput = (
    <>
      <NextLineDivider tablet />
      <NxMultiSelect<number> className={styles.input}
                             data-testid={DataTestIds.OCCUPATION_SELECT}
                             label={<PrefixTrans>OCCUPATION</PrefixTrans>}
                             onChange={handleOccupationChange}
                             options={occupationOptions ?? []}
                             value={!isNil(check.occupationIds) ? check.occupationIds : []} />
      {endButton}
    </>
  );

  console.log('occupationOptions', occupationOptions, check);

  const occupationAndIncomeCheckInput = (
    <>
      <NextLineDivider tablet />
      <NextLineDivider />
      <NextLineDivider />
      <NxMultiSelect<number> className={styles.input}
                             data-testid={DataTestIds.OCCUPATION_SELECT}
                             label={<PrefixTrans>OCCUPATION</PrefixTrans>}
                             onChange={(value): void => handleOccupationsChange(value)}
                             options={occupationOptions ?? []}
                             value={!isNil(occupationIds) ? occupationIds : []} />
      {!isNil(occupationIds) && occupationIds.map((occupationId, index) => {
        const option = occupationOptions?.find(option => option.value === occupationId);
        console.log('Occupation Id', occupationId, index);
        return <>
          <NxInput className={styles.input}
                   label={<PrefixTrans>OCCUPATION</PrefixTrans>}
                   disabled
                   value={option?.label?.toString()} />
          <NxNumberInput className={styles.input}
                         data-testid={DataTestIds.INCOME_INPUT}
                         label={<PrefixTrans>MIN_INCOME</PrefixTrans>}
                         value={check?.occupationAndIncomeItems && check?.occupationAndIncomeItems[index].minIncome}
                         onChange={handleIncomeChange('minIncome', index)} />
          <NxNumberInput className={styles.input}
                         data-testid={DataTestIds.INCOME_INPUT}
                         label={<PrefixTrans>MAX_INCOME</PrefixTrans>}
                         value={check?.occupationAndIncomeItems && check?.occupationAndIncomeItems[index].maxIncome}
                         onChange={handleIncomeChange('maxIncome', index)} />
        </>;
      })}

      {endButton}
    </>
  );

  const integerCheckInput = (
    <>
      <NextLineDivider tablet />
      <NxNumberInput className={styles.input}
                     data-testid={DataTestIds.INTEGER_INPUT}
                     label={<PrefixTrans>INTEGER_LABEL</PrefixTrans>}
                     value={check.integerValue}
                     onChange={handleNumberInputChange('integerValue')} />
      {endButton}
    </>
  );

  const booleanCheckInput = (
    <>
      <NextLineDivider tablet />
      <NextLineDivider />
      <NxSelect<boolean> className={styles.input}
                         data-testid={DataTestIds.BOOLEAN_SELECT}
                         label={<PrefixTrans>BOOLEAN_LABEL</PrefixTrans>}
                         onChange={handleBooleanChange}
                         options={[{value: true, label: 'Yes', disabled: false},
                           {value: false, label: 'No', disabled: false}]}
                         value={check.booleanValue ?? false} />
      {endButton}
    </>
  );

  const cashCheckInput = (
    <>
      <NextLineDivider tablet />
      <NextLineDivider />
      <NxNumberInput className={styles.input}
                     data-testid={DataTestIds.CASH_INPUT}
                     label={<PrefixTrans>CASH_LABEL</PrefixTrans>}
                     value={check.cashValue}
                     onChange={handleNumberInputChange('cashValue')} />
      {endButton}
    </>
  );

  const percentageCheckInput = (
    <>
      <NextLineDivider tablet />
      <NextLineDivider />
      <NxPercentageInput className={styles.input}
                         data-testid={DataTestIds.PERCENTAGE_INPUT}
                         label={<PrefixTrans>PERCENTAGE_LABEL</PrefixTrans>}
                         value={check.percentageValue}
                         onChange={handleNumberInputChange('percentageValue')} />
      {endButton}
    </>
  );

  const tableCheckInput = (
    <>
      <NextLineDivider tablet />
      <TableCheckSidePanel tableItems={check.tableItems} onSaveItems={handleTableItemsChange} />
      {endButton}
    </>
  );

  const handleTypeChange = (type: string | null): void => {
    setCheck({
      ...check,
      type: type as CheckType,
      ...automaticChecksInitValues,
      // Set to false for these config checks as default when selected, 
      // as they are currently used for configuration purposes only 
      // and don't have backend validation logic implemented yet
      enabled: !CONFIGURATION_CHECKS.includes(type || '')
    });
  };

  const manualCheckForm = <>
    <NxSelect className={styles.input}
              data-testid={DataTestIds.PROOF_TYPE_SELECT}
              label={<PrefixTrans>CRITERION_TYPE</PrefixTrans>}
              onChange={handleProofTypeChange}
              options={getFieldTypeOptions(t)}
              value={proofType ?? ''} />
    <NextLineDivider tablet />
    <div className={styles.buttons}>
      {
        isDictionaryType(proofType) &&
        <CustomDictionarySidePanel handleSave={handleDictionaryChange}
                                   dictionaryId={dictionaryId}
                                   phaseId={phaseId} />
      }
      <SelfCareButtonSwitch onClick={(): void => handleSwitchChange('selfCare')} value={selfCare} />
      <RequiredButtonSwitch onClick={(): void => handleSwitchChange('required')} value={required} />
      {endButton}
    </div>
  </>;

  const automaticCheckForm = <>
    <NxSelect className={styles.input}
              data-testid={DataTestIds.CRITERION_TYPE_SELECT}
              label={<PrefixTrans>CRITERION_TYPE</PrefixTrans>}
              onChange={handleTypeChange}
              value={type ?? ''}
              options={automaticCheckTypeOptions} />
    {
      type === CheckType.AGE && ageCheckInputs
    }
    {
      type === CheckType.INCOME && incomeCheckInputs
    }
    {
      type === CheckType.PROVINCE && provinceCheckInput
    }
    {
      type === CheckType.RESIDENCY && residencyCheckInput
    }
    {
      type === CheckType.NATIONALITY && nationalityCheckInput
    }
    {
      type === CheckType.OCCUPATION && occupationCheckInput
    }
    {
      type === CheckType.OCCUPATION_AND_INCOME && occupationAndIncomeCheckInput
    }
    {
      type === CheckType.INTEGER && integerCheckInput
    }
    {
      type === CheckType.BOOLEAN && booleanCheckInput
    }
    {
      type === CheckType.CASH && cashCheckInput
    }
    {
      type === CheckType.PERCENTAGE && percentageCheckInput
    }
    {
      type === CheckType.TABLE && tableCheckInput
    }
  </>;

  return (
    <>
      <NxCheckbox checked={check.enabled} onChange={(event): void => handleEnableChange(event)} />
      <div className={styles.row}>
        <div className={clsx(styles.input, styles.inputSelectWrapper, {[styles.input_long]: false})}>
        <NxMultiSelect
          className={styles.inputSelect}
          label={<Trans>COMMON.FIELDS.ACTION_OWNER</Trans>}
          options={actionOwnerOptions}
          onChange={handleActionOwnerChange}
          value={actionOwnerSelectValue} />

        <Tooltip title={'Any of the selected action owners can input in the field.'}>
          <InfoIcon />
        </Tooltip>
      </div>

      <NxInput className={styles.input}
               label={<PrefixTrans>CRITERION_NAME</PrefixTrans>}
               onChange={handleInputChange('name')}
               value={check.name} />
      {automatic ? automaticCheckForm : manualCheckForm}
    </div></>
  );
};

export default Check;
