import {FieldType} from './field.model';

export interface CheckTO<T_PROOF_TYPE = string, T_TYPE = string> {
  automatic?: boolean;
  name?: string;
  orderNo: number;
  phaseId?: number;
  required: boolean;
  selfCare: boolean;
  proofType?: T_PROOF_TYPE;
  type?: T_TYPE;
  roleIds: number[];
  dictionaryId?: number;
  maxAge?: number;
  minAge?: number;
  maxIncome?: number;
  minIncome?: number;
  provinceIds?: number[];
  residenceIds?: number[];
  nationalityIds?: number[];
  ageCheckTypeId?: number;
  occupationIds?: number[];
  integerValue?: number;
  booleanValue?: boolean;
  cashValue?: number;
  percentageValue?: number;
  tableItems?: TableCheckItem[];
  occupationAndIncomeItems?: OccupationAndIncomeCheckItem[]
  enabled: boolean;
  id?: number;
}

export type Check = CheckTO<FieldType, CheckType>;

export const fromCheckTO = (checkTO: CheckTO): Check => ({
  ...checkTO,
  proofType: checkTO.proofType ? FieldType[checkTO.proofType] : undefined,
  type: checkTO.type as CheckType
});

export interface DraggableCheckItem extends Check {
  key: string;
}

export interface TableCheckItem<T_PROOF_TYPE = string> {
  fieldType?: T_PROOF_TYPE;
  name?: string;
  value?: string;
}
export interface OccupationAndIncomeCheckItem {
  occupationId: number;
  maxIncome?: number;
  minIncome?: number;
}

export enum CheckType {
  AGE = 'AgeCheckView',
  INCOME = 'IncomeCheckView',
  MANUAL = 'ManualCheckView',
  PROVINCE = 'ProvinceCheckView',
  RESIDENCY = 'ResidencyCheckView',
  NATIONALITY = 'NationalityCheckView',
  OCCUPATION = 'OccupationCheckView',
  OCCUPATION_AND_INCOME = 'OccupationAndIncomeCheckView',
  INTEGER = 'IntegerCheckView',
  BOOLEAN = 'BooleanCheckView',
  CASH = 'CashCheckView',
  PERCENTAGE = 'PercentageCheckView',
  TABLE = 'TableCheckView'
}
